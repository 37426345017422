'use strict';

;
(function ($) {

  //Popup on about page
  $('.js-about-popup').magnificPopup({
    type: 'inline',
    fixedBgPos: true,
    fixedContentPos: true
  });

  //Init gallery on product page
  $('.gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    overflowY: 'scroll',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    }
  });

  $(document)
  //Menu Toggle
  .on('click', '.js-menu-toggle', function () {
    $('.header').toggleClass('header--menu-opened');
  })
  //Scroll to top page
  .on('click', '.js-product-scroll', function () {
    $('.content').velocity('scroll');
  })
  //Last breadcrumb scroll to top page
  .on('click', '.breadcrumbs__current', function () {
    $('html').velocity('scroll');
    return false;
  })
  //Change form in about popup
  .on('click', '.js-change-form', function () {
    var $this = $(this),
        $target_form = $($this.data('form'));

    $('.contacts__header-item').removeClass('active');
    $this.addClass('active');
    $('.form').hide();
    $target_form.show();
  });

  //Counter slides on home page
  $('.home-slider').on('init', function (event, slick) {
    console.log(slick.slideCount);
    $('.home-slider-count .home-slider-count__current').text(pad(slick.currentSlide + 1, 2));
    $('.home-slider-count .home-slider-count__all').text(pad(slick.slideCount, 2));
  });

  $('.home-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $('.home-slider-count .home-slider-count__current').text(pad(nextSlide + 1, 2));
  });

  //Sldier on home page
  $('.home-slider').slick({
    slide: '.home-slide',
    fade: true,
    dots: true,
    prevArrow: $('.home-slider__prev'),
    nextArrow: $('.home-slider__next')
  });

  //Add extra zero
  function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
  }
})(jQuery);